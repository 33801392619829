import { BankingCounterparty } from './banking-counterparty';
import { CollectionFundTransferSender } from './collection-fund-transfer-sender';
import { ManagingPlatform } from './externally-managed';
import { RoutingInfo } from './bank-account';
import { Hedge } from './hedge';
import { QuoteSource } from './hedge-proposal';
import { Spot } from './spot';
import { Funding } from './funding';
import { BulkFullSettlement, CycleSettlement, OffsetSettlement } from './settlement';

export enum Direction {
  Credit = 'credit',
  Debit = 'debit'
}

export enum FundTransferType {
  // Incoming funds from customers, paying for a Hedge (covering a HedgePayment)
  Collection = 'collection',
  // Funds transferred from Partner to a Payment Beneficiary
  Withdraw = 'withdraw',
  // Funds needs to be sent from Grain treasury account to Partner
  TreasuryOutbound = 'treasury-outbound',
  // Funds needs to be sent from Partner account to Grain treasury
  TreasuryInbound = 'treasury-inbound',
  // Funds sent from Partner collection accounts to hedged currency accounts as part of the hedging flow
  HedgingSpotOutbound = 'hedging-spot-outbound',
  // Funds received from Partner collection accounts into hedged currency accounts as part of the hedging flow
  HedgingSpotInbound = 'hedging-spot-inbound',
  // Funds needs to be received from Grain treasury after hedging spot
  InternalOffsetInbound = 'internal-offset-inbound',
  // Funds needs to be sent from Partner account to Grain after hedging spot
  InternalOffsetOutbound = 'internal-offset-outbound',
  // This transfer represent the amount of money reduced from the partner
  // bank account, "fromCurrency" (the amount we hedged - equal to the spotOutbound amount)
  HedgingSettlementOutbound = 'hedging-settlement-outbound',
  // This transfer represent the amount received in the "toCurrency" bank account
  // after hedge is completed. The amount will be summary of spotInbound amount and offset amount
  HedgingSettlementInbound = 'hedging-settlement-inbound',
  // This transfer represent the amount of money reduced from the partner bank account, by the offset currency
  OffsetSettlementOutbound = 'offset-settlement-outbound',
  // This transfer represent the amount of money received from grain treasury bank account, by the offset currency
  OffsetSettlementInbound = 'offset-settlement-inbound',
  // This transfer represent the amount of money reduced from the partner bank account, by the offset currency
  CycleSettlementOutbound = 'cycle-settlement-outbound',
  // This transfer represent the amount of money received from grain treasury bank account, by the offset currency
  CycleSettlementInbound = 'cycle-settlement-inbound',
  // This transfer represent the amount converted from the original bank account
  SpotOutbound = 'spot-outbound',
  // This transfer represent the amount converted to the destination bank account
  SpotInbound = 'spot-inbound'
}

export enum FundTransferStatus {
  Requested = 'requested', // An instruction has been requested by a Partner,it is now pending approval by an Admin
  Scheduled = 'scheduled', // An instruction has been issued by us but not yet acknowledged & persisted back to us
  Pending = 'pending', // An instruction is awaiting execution by an external provider
  Completed = 'completed', // The instruction has completed execution on the external provider
  Cancelled = 'cancelled', // The instruction's execution on the external provider was cancelled
  Failed = 'failed' // The fund transfer failed due to an error
}

export interface FundTransfer {
  id: string;
  managedBy: ManagingPlatform;
  completedAt: Date;
  createdAt: Date;
  externalId: string;
  type: string;
  referenceId: string;
  amount: number;
  currency: string;
  status: string;
  reconciledAt: Date;
  fromCounterpartyName: string;
  partnerName: string;
  partnerId: string;
  note: string;
}

export interface BaseFundTransfer {
  id: string;
  amount: number;
  currency: string;
  managedBy: ManagingPlatform;
  status: FundTransferStatus;
  fromCounterparty: BankingCounterparty;
  toCounterparty: BankingCounterparty;
  completedAt?: Date;
  createdAt: Date;
  externalId: string;
  valueDate?: Date;
  note?: string;
  type: FundTransferType;
}

export interface HedgingFundTransfer extends BaseFundTransfer {
  listOfConversionAmounts?: CurrencyAmountType[];
}

export interface CollectionFundTransfer extends BaseFundTransfer {
  direction: Direction;
  reconciledAt: Date;
  sender: CollectionFundTransferSender;
  usedRoutingInfo: RoutingInfo;
  amountLeft: number;
  canReconcile?: boolean;
  reference?: string;
  fundings?: Funding[];
}

export type InternalOffsetFundTransfer = BaseFundTransfer;

export type InternalOffsetInboundFundTransfer = InternalOffsetFundTransfer;

export interface TreasuryOutboundFundTransfer extends BaseFundTransfer {
  referenceId: string;
  sourceInternalOffsetInboundTransfers: InternalOffsetInboundFundTransfer[];
}

export interface TreasuryInboundFundTransfer extends BaseFundTransfer {
  sourceInternalOffsetOutboundTransfers: InternalOffsetOutboundFundTransfer[];
  paymentBeneficiaryRoutingInfo: RoutingInfo;
}

export interface InternalOffsetOutboundFundTransfer extends InternalOffsetFundTransfer {
  treasuryInboundFundTransfer?: TreasuryInboundFundTransfer;
}

export interface HedgingSpotReport {
  provider: QuoteSource | null;
  date: Date | null;
  actualQuote: number | null;
  fromCurrency: string | null;
  fromCurrencyAmount: number | null;
  toCurrency: string | null;
  toCurrencyAmount: number | null;
}

export interface HedgingSpotFundTransfer extends HedgingFundTransfer {
  quoteSource: QuoteSource;
  actualQuote: number;
}

export interface HedgingSpotOutboundFundTransfer extends HedgingSpotFundTransfer {
  hedgingSpotInboundFundTransfer: HedgingSpotInboundFundTransfer;
  toCurrency: string;
}

export interface HedgingSpotInboundFundTransfer extends HedgingSpotFundTransfer {
  hedgingSpotOutboundFundTransfer: HedgingSpotOutboundFundTransfer;
  fromCurrency: string;
}

export interface SpotOutboundFundTransfer extends BaseFundTransfer {
  quoteSource: QuoteSource;
  actualQuote: number;
  toCurrency: string;
  spot: Spot;
}

export interface SpotInboundFundTransfer extends BaseFundTransfer {
  quoteSource: QuoteSource;
  actualQuote: number;
  fromCurrency: string;
  amountLeft: number;
  spot: Spot;
  fundings?: Funding[];
}

export interface WithdrawalFundTransfer extends BaseFundTransfer {
  paymentBeneficiaryRoutingInfo: RoutingInfo;
}

export interface HedgingSettlementFundTransfer extends HedgingFundTransfer {
  hedge?: Hedge;
  bulkFullSettlement?: BulkFullSettlement;
}
export type HedgingSettlementOutboundFundTransfer = HedgingSettlementFundTransfer;

export interface HedgingSettlementInboundFundTransfer extends HedgingSettlementFundTransfer {
  amountLeft: number;
  fundings?: Funding[];
}

export interface OffsetSettlementFundTransfer extends BaseFundTransfer {
  offsetSettlement: OffsetSettlement;
}

export interface OffsetSettlementInboundFundTransfer extends OffsetSettlementFundTransfer {
  amountLeft: number;
  fundings?: Funding[];
}

export interface CycleSettlementFundTransfer extends BaseFundTransfer {
  cycleSettlement: CycleSettlement;
}

export interface CycleSettlementInboundFundTransfer extends CycleSettlementFundTransfer {
  amountLeft: number;
  fundings?: Funding[];
}

export type ExternalPaymentFundTransfer = WithdrawalFundTransfer | TreasuryInboundFundTransfer;

export type CurrencyAmountType = { currency: string; amount: number };
